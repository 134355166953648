export const sex = [
  { value: '女', label: '女' },
  { value: '男', label: '男' }
]

export const type = [
  { value: '全职', label: '全职' },
  { value: '兼职', label: '兼职' }
]

export const status = [
  { value: '正式', label: '正式' },
  { value: '试用', label: '试用' }
]

export const marriage = [
  { value: '未婚', label: '未婚' },
  { value: '已婚', label: '已婚' },
  { value: '离异', label: '离异' },
  { value: '丧偶', label: '丧偶' },
  { value: '分局', label: '分局' },
]

export const political = [
  { value: '团员', label: '团员' },
  { value: '党员', label: '党员' },
  { value: '群众', label: '群众' },
  { value: '其他', label: '其他' },
]

export const residence = [
  { value: '本地城镇', label: '本地城镇' },
  { value: '本地农村', label: '本地农村' },
  { value: '外地城镇（省内）', label: '外地城镇（省内）' },
  { value: '外地农村（省内）', label: '外地农村（省内）' },
  { value: '外地城镇（省外）', label: '外地城镇（省外）' },
  { value: '外地农村（省外）', label: '外地农村（省外）' },
]

export const corp_probation_period = [
  { value: 0, label: '无试用期' },
  { value: 1, label: '一个月' },
  { value: 2, label: '二个月' },
  { value: 3, label: '三个月' },
  { value: 4, label: '四个月' },
  { value: 5, label: '五个月' },
  { value: 6, label: '六个月' },
  { value: 999, label: '其他' },
]
